import $ from '../core/Dom';
import Config from '../core/Config';
import gsap from 'gsap';
import superagent from 'superagent';
import { loadVimeo } from "../lib/async-bundles";

export default el => {
    let $container = $(el);

    let $image = $container.find('[data-image-area]');
    let $button = $container.find('[data-play-button]');
    let $headingArea = $container.find('[data-heading-area]');
    let $videoWrapper = null;
    let isLoading = null;


    const init = () => {
        $videoWrapper = $('<div class="video-embed-wrapper"></div>');
        $container.append($videoWrapper);
        $videoWrapper.css({ display: 'none' });

        $container.on('click', '[data-play-button]', (e) => {
            e.preventDefault();
            //console.log($(this).attr('href'));
            loadVideo($(e.triggerTarget).attr('href'));
        });

    }

    const loadVideo = (src) => {
        if (isLoading) {
            return;
        }

        gsap.to($image.get(0), { duration: 0.4, delay: 0.3, opacity: 0 });
        gsap.to($button.get(0), { duration: 0.2, opacity: 0 });
        gsap.to($headingArea.get(0), { duration: 0.2, delay: 0.3, opacity: 0 });

        isLoading = true;

        superagent
            .get(Config.get('loadWorkVideo'))
            .set({ 'X-Requested-With': 'XMLHttpRequest' })
            .query({ videosrc: src })
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                const $newData = $('<div>' + text + '</div>');
                insertVideo($newData);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
            });
        /*
        if (this.loadReq === null) {
            this.loadReq = $.ajax(FirstFrame.Paths.LoadWorkVideo, {
                cache: false,
                data: { videosrc: src },
                type: 'POST',
                dataType: 'html'
            }).done(function(data) {
                self.loadReq = null;
                var $new_data = $('<div>' + data + '</div>');
                self.insert_video($new_data);
            }).fail(function() {
                self.loadReq = null;
            });
        }
        
         */
    };


    const insertVideo = ($data) => {
        const $embed = $data.find('.video-embed');

        if ($embed.length > 0) {
            const $iframe = $embed.find('iframe');
            const iframeWidth = parseInt($iframe.attr('width'));
            const iframeHeight = parseInt($iframe.attr('height'));

            $embed.css({ position: 'relative', width: '100%', height: 0, paddingBottom: ((iframeHeight / iframeWidth) * 100) + '%' });
            //$iframe.attr('width', '');
            //$iframe.attr('height', '');
            $iframe.css({ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 });

            $image.css({ display: 'none' });
            $headingArea.css({ display: 'none' });

            $videoWrapper.empty();
            $videoWrapper.css({ display: 'block', opacity: 0 });
            $videoWrapper.append($embed);
            
            loadVimeo(loadedVimeo => {
                const VimeoPlayer = loadedVimeo.default;
                const player = new VimeoPlayer($iframe.get(0));

                player.ready().then(() => {
                    gsap.to($videoWrapper.get(0), { duration: 0.5, delay: 0.3, opacity: 1 });

                    player.on('ended', function() {
                        resetVideoArea();
                    });
                });
            });
        }
    };


    const resetVideoArea = () => {
        gsap.to($videoWrapper.get(0), {
            duration: 0.1, opacity: 0, onComplete: () => {
                $videoWrapper.css({ display: 'none' });
                $image.css({ display: 'block', opacity: 0 });
                $headingArea.css({ display: 'block', opacity: 0 });
                gsap.to($image.get(0), { duration: 0.4, opacity: 1 });
                gsap.to($button.get(0), { duration: 0.2, delay: 0.3, opacity: 1 });
                gsap.to($headingArea.get(0), { duration: 0.2, opacity: 1 });
            }
        });
    };

    const destroy = () => {
        if ($container.length > 0) {
            $container.off('click');
        }

        $container = null;
        $image = null;
        $headingArea = null;
        $button = null;

        if ($videoWrapper !== null) {
            $videoWrapper.empty();
        }
        
        $videoWrapper = null;
    };

    return {
        init,
        destroy
    };

};
