import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Config from '../core/Config';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import superagent from 'superagent';

import * as eventKeys from '../lib/events';

export default el => {
    let $container = $(el);
    let $body = $('body');

    let $mainNav = null;
    let $menuItems = null;

    let $workPanel = null;
    let $workPanelInner = null;
    let $workPanelContent = null;
    let $workPanelLoader = null;
    let $workCloseBtn = null;
    let $workOpenBtn = null;

    let workLoaded = false;
    let isLoading = null;
    let collapsedMenu = false;
    let currentScrollTop = 0;
    let isHidden = false;
    let isLocked = false;

    let workVisible = false;
    let menuVisible = false;
    let currentBreakpoint = 1;
    const breakpointFullMenu = 752;

    const init = () => {
        currentBreakpoint = Viewport.breakpoint.size;

        $mainNav = $('.main-nav');
        $menuItems = $mainNav.find('.menu-item');

        $workPanel = $container.find('.work-panel');
        $workPanelInner = $container.find('.work-panel-inner');
        $workPanelContent = $container.find('.work-panel-content');
        $workPanelLoader = $container.find('.inpage-loader.project-pullout');
        $workCloseBtn = $container.find('.work-toggle.close');
        $workOpenBtn = $container.find('.work-toggle.open');

        // inits
        initMenu();
        initWorkPanel();

        // listeners
        Viewport.on('breakpoint', breakpointChanged);
        Viewport.on('scroll', windowScroll);

        $body.on('keyup', (e) => {
            if (e.keyCode === 27) { // ESC
                if (workVisible) {
                    hideWork();
                }
                if (menuVisible) {
                    hideMenu();
                }
            }
        });
    };

    const destroy = () => {

    };

    const initMenu = () => {
        // menu toggle click handler
        $container.on('click', '.menu-toggle', (e) => {
            e.preventDefault();
            const $link = $(e.triggerTarget);

            if ($link.hasClass('open')) {
                showMenu();
            } else {
                hideMenu();
            }
        });


        $menuItems.on('click', (e) => {
            if (currentBreakpoint < breakpointFullMenu) {
                hideMenu();
            }
        });
    };


    const initWorkPanel = () => {
        // menu toggle click handler
        $container.find('.work-toggle').on('click', (e) => {
            e.preventDefault();

            const $link = $(e.triggerTarget);

            if ($link.hasClass('open')) {
                showWork();
            } else {
                hideWork();
            }
        });

        // history enabled links
        $workPanelContent.on('click', 'a.history-enabled', (e) => {
            e.preventDefault();

            const src = $(e.triggerTarget).attr('href');
            Dispatch.emit(eventKeys.OPEN_NEW_HISTORY_URL, { newUrl: src })
            // window.history.pushState({}, "", src);

            setTimeout(function() {
                hideWork();
            }, 200);
        });


        $workPanelInner.on('click', '[data-category-item] a', (e) => {
            e.preventDefault();
            const $link = $(e.triggerTarget);
            loadWorkFiltered($link);
        });


        $workPanel.on('click', (e) => {
            hideWork();
        });

        $workPanelInner.on('click', (e) => {
            if (!$(e.triggerTarget).hasClass('work-toggle')) {
                e.stopImmediatePropagation();
            }
        });
    };


    const breakpointChanged = (e) => {
        let data = e.detail;
        
        // reset any styling that was set on menu in the small version when going big
        if (data.old.size < breakpointFullMenu && data.current.size >= breakpointFullMenu) {
            $mainNav.css({ height: '' });
            $menuItems.css({ opacity: '', top: '' });
            menuVisible = false;
        }

        currentBreakpoint = data.current.size;
    };


    const windowScroll = (e, data) => {
        const scrollTop = Viewport.scrollTop;

        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }

        if (scrollTop < 20 && collapsedMenu) {
            $container.removeClass('smaller');
            collapsedMenu = false;
        } else if (scrollTop > 100 && !collapsedMenu) {
            $container.addClass('smaller');
            collapsedMenu = true;
        }
        
        if (scrollTop < 100) {
            show();
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
        }
        
        currentScrollTop = scrollTop;
    };
    
    const hide = (force) => {
        if (isHidden) {
            return;
        }
        isHidden = true;

        if (force) {
            $container.addClass('is-forced');
            
            setTimeout(() => {
                $container.removeClass('is-forced');
            }, 600)
        }
        
        $container.addClass('is-hidden');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        $container.removeClass('is-hidden');
    };

    const lock = () => {
        if (isLocked) {
            return;
        }
        isLocked = true;
        $container.addClass('is-locked');
    };

    const unlock = () => {
        if (!isLocked) {
            return;
        }
        isLocked = false;
        $container.removeClass('is-locked');
    };
    
    
    const showMenu = () => {
        $mainNav.css({ height: '0' });
        $menuItems.css({ opacity: 0, top: -20 });

        gsap.to($mainNav.get(0), { duration: 0.6, height: '100%', ease: 'quint.out' });

        $menuItems.each((item, i) => {
            gsap.to(item, { duration: 1, delay: 0.1 + (i * 0.1), opacity: 1 });
            gsap.to(item, { duration: 0.6, delay: 0.1 + (i * 0.1), top: 0, ease: 'quint.out' });
        });

        menuVisible = true;
    };

    const hideMenu = () => {
        gsap.to($mainNav.get(0), { duration: 0.4, delay: 0.1, height: '0%', ease: 'sine.inOut' });

        $menuItems.each(item => {
            gsap.to(item, { duration: 0.2, opacity: 0 });
        });

        menuVisible = false;
    };

    const showWork = () => {
        $workPanel.css({ left: '100%' });
        $workPanelContent.css({ opacity: 0 });

        $workCloseBtn.css({ display: 'block' });
        $workOpenBtn.css({ display: 'none' });

        $workPanelInner.css({ 'overflow-y': 'scroll' });

        $('body').css({ 'overflow-y': 'hidden' });

        gsap.to($workPanel.get(0), {
            duration: 1, left: 0, ease: 'quint.out', onComplete: () => {

                if (!workLoaded) {
                    loadWork(Config.get('workPanelUrl'));
                }

                Dispatch.emit(eventKeys.WORK_PANEL_OPENED);
            }
        });

        if (workLoaded) {
            gsap.to($workPanelContent.get(0), { duration: 0.6, delay: 0.4, opacity: 1 });
        }

        workVisible = true;
    };

    const hideWork = () => {
        $workPanelInner.css({ 'overflow-y': '' });
        $body.css({ 'overflow-y': '' });

        Dispatch.emit(eventKeys.WORK_PANEL_CLOSED);

        $workCloseBtn.css({ display: '' });
        $workOpenBtn.css({ display: '' });

        gsap.to($workPanel.get(0), { duration: 0.5, left: '100%', ease: 'sine.out' });

        if (workLoaded) {
            gsap.to($workPanelContent.get(0), { duration: 0.2, opacity: 0 });
        }

        workVisible = false;
    };

    const loadWork = (url) => {
        if (isLoading) return;

        $workPanelLoader.css({ display: 'block', opacity: 0 }).addClass('animated');
        gsap.to($workPanelLoader.get(0), { duration: 0.2, delay: 0.2, opacity: 1 });

        const $workList = $workPanelContent.find('[data-work-list]');
        if ($workList.length > 0) {
            gsap.to($workList.get(0), { duration: 0.2, opacity: 0 });
        }
        isLoading = true;

        superagent
            .get(url)
            .set({ 'X-Requested-With': 'XMLHttpRequest' })
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }

                const $new_data = $('<div>' + text + '</div>');
                insertWorkData($new_data);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
            });
    };

    const insertWorkData = ($data) => {
        if ($workPanelContent.get(0).children.length === 0) {
            $workPanelContent.append($data);
            $workPanelContent.find('[data-category-item] a').removeClass('history-enabled');
            gsap.to($workPanelContent.get(0), { duration: 0.6, delay: 0.3, opacity: 1 });
        } else {
            const $workList = $workPanelContent.find('[data-work-list]');
            $workList.empty();
            $workList.append($data.find('[data-work-list] li'));
            gsap.to($workList.get(0), { duration: 0.6, delay: 0.3, opacity: 1 });
        }

        workLoaded = true;

        gsap.to($workPanelLoader.get(0), {
            duration: 0.2, opacity: 0, overwrite: true, onComplete: () => {
                $workPanelLoader.css({ display: 'none' }).removeClass('animated');
            }
        });

        // TODO : init lazysizes if I end up using that!
    };

    const loadWorkFiltered = ($link) => {
        $workPanelContent.find('[data-category-item] a').removeClass('selected');
        $link.addClass('selected');

        loadWork($link.attr('href'));
    };

    return {
        init,
        destroy
    };
};
