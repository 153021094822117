import $ from '../core/Dom';
import gsap from 'gsap';

export default el => {
    let $header = $(el);

    let $image = $header.find('.anim-image');
    let $text = $header.find('.anim-text');

    const init = () => {
        $image.css({ opacity: 0 });
        $text.css({ opacity: 0, marginTop: -30 });

        gsap.to($image.get(0), { duration: 0.6, delay: 0.5, opacity: 1 });

        gsap.to($text.get(0), { duration: 1, delay: 1, opacity: 1 });
        gsap.to($text.get(0), { duration: 1, delay: 1, marginTop: 0, ease: 'quint.out' });
    }

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
