import $ from '../core/Dom';
import gsap from 'gsap';
import TinyGesture from 'tinygesture';

export default el => {
    let $el = $(el);
    let $slides = $el.find('[data-slide]');
    let $currentSlide = null;
    let currentIndex = 0;
    let changeTimeout = -1;

    const init = () => {
        $slides.css({ position: 'absolute', left: 0, top: 0, width: '100%', opacity: 0 });
        $slides.eq(0).css({ position: 'relative', opacity: 1 });

        $el.on('click', '[data-nav-btn]', (e) => {
            console.log('click');
            e.preventDefault();

            const $link = $(e.triggerTarget);

            clearTimeout(changeTimeout);

            if ($link.data('nav-btn') === 'next') {
                nextSlide();
            } else if ($link.data('nav-btn') === 'prev') {
                prevSlide();
            }
        });

        showCurrentSlide();

        // swipes
        const gesture = new TinyGesture($el.get(0), {});
        gesture.on('swiperight', (event) => { prevSlide(); });
        gesture.on('swipeleft', (event) => { nextSlide(); });
    };
    
    const showCurrentSlide = () => {
        $slides.css({ zIndex: '2' });

        if ($currentSlide !== null) {
            $currentSlide.css({ zIndex: '3' });
        }

        $currentSlide = $slides.eq(currentIndex);
        $currentSlide.css({ display: 'block', zIndex: 4 });

        gsap.set($currentSlide.get(0), { opacity: 0, scale: 1 });
        gsap.to($currentSlide.get(0), { duration: 1, opacity: 1, ease: 'sine.out' });
    };

    const nextSlide = () => {
        currentIndex += 1;
        if (currentIndex >= $slides.length) {
            currentIndex = 0;
        }

        showCurrentSlide();
    };

    const prevSlide = () => {
        currentIndex -= 1;
        if (currentIndex < 0) {
            currentIndex = $slides.length - 1;
        }

        showCurrentSlide();
    };

    const destroy = () => {
        clearTimeout(changeTimeout);

        if ($currentSlide !== null) {
            gsap.killTweensOf($currentSlide.get(0));
        }

        $slides = null;
        $currentSlide = null;
    };

    return {
        init,
        destroy
    };

};
