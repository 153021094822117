import $ from '../core/Dom';
import gsap from 'gsap';

const $container = $('.site-loader');
const $spans = $('.site-loader span');

let pageReady = false;
let startAnimationDone = false;

const init = () => {
    $spans.css({ opacity: 0 });

    gsap.to($spans.get(0), { duration: 0.3, delay: 0.2, opacity: 1, ease: 'sine.out' });
    gsap.to($spans.get(1), { duration: 0.3, delay: 0.3, opacity: 1, ease: 'sine.out' });
    gsap.to($spans.get(2), { duration: 0.3, delay: 0.5, opacity: 1, ease: 'sine.out' });
    gsap.to($spans.get(3), {
        duration: 0.3, delay: 0.6, opacity: 1, ease: 'sine.out', onComplete: () => {
            startAnimationDone = true;
            if (pageReady) {
                setTimeout(() => {
                    hide();
                }, 800);
            }
        }
    });

    window.addEventListener('load', () => {
        windowLoaded();
    });
};


const windowLoaded = () => {
    pageReady = true;
    if (startAnimationDone) {
        hide();
    }
};

const show = () => {
    $container.css({ display: 'block', opacity: 0 });
    gsap.to($container, { duration: 0.2, opacity: 1 });

    $spans.css({ opacity: 0 });
    gsap.to($spans.get(0), { duration: 0.6, delay: 0.2, opacity: 1 });
    gsap.to($spans.get(1), { duration: 0.6, delay: 0.3, opacity: 1 });
    gsap.to($spans.get(2), { duration: 0.6, delay: 0.6, opacity: 1 });
    gsap.to($spans.get(3), { duration: 0.6, delay: 0.8, opacity: 1 });

};

const hide = () => {
    gsap.to($spans.nodes, { duration: 0.2, delay: 0, opacity: 0 });
    gsap.to($container.get(0), {
        duration: 0.3, delay: 0.3, opacity: 0, onComplete: () => {
            $container.css({ display: 'none' });
        }
    });
};

export default {
    init,
    show,
    hide
};
