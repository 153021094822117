import $ from './core/Dom';
import Viewport from './core/Viewport';
import Config from './core/Config';
import Dispatch from './core/Dispatch';
import Components from './core/Components';
//import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import SiteLoader from './lib/SiteLoader';
import Animator from './lib/Animator';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import superagent from 'superagent';

import * as eventKeys from './lib/events';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);

let $body = $('body');
let impressumVisible = false;
let loader = null;
let history = null;
let currentUrl = null;
let isLoading = false;

let $mainContent = $('.main-content');
let $mainContentInner = $('.main-content-inner');
let $inpageLoader = $('.inpage-loader.site');

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    SiteLoader.init();
    Animator.init();
    
    //lazySizes.init();


    //loader = new SiteLoader();

    history = window.History;

    if (history) {
        window.addEventListener('popstate', e => {
            triggerHistory(document.location.href);
        });
    }

    // share link
    $body.on('click', 'a[data-share-link]', (e) => {
        e.preventDefault();
        openShareWindow($(e.triggerTarget).attr('href'));
    });

    // to top button
    $body.on('click', '.to-top-link', (e) => {
        e.preventDefault();
        scrollToTop();
    });

    // impressum click
    $body.on('click', '.impressum-toggle-link', (e) => {
        e.preventDefault();
        toggleImpressum($(e.triggerTarget));
    });

    // history enabled links
    $body.on('click', 'a.history-enabled, .wysiwyg a[href^="' + Config.get('siteUrl') + '"]', (e) => {
        if (history) {
            e.preventDefault();
            const $trigger = $(e.triggerTarget);
            const src = $trigger.attr('href');

            if ($trigger.hasClass('.back-button')) {
                self.history.back();
            } else {
                self.history.pushState({}, "", src);
                loadUrl(src);
            }
        }
    });

    Dispatch.on(eventKeys.OPEN_NEW_HISTORY_URL, onOpenNewHistoryUrl);
};

const onOpenNewHistoryUrl = (key, data) => {
    const { newUrl } = data;
    
    if (newUrl !== currentUrl) {
        window.history.pushState({}, "", newUrl);
        loadUrl(newUrl);
    }
};

const triggerHistory = (newUrl) => {
    //console.log('new url is ' + newUrl);

    if (newUrl !== currentUrl) {
        loadUrl(newUrl);
    }
};

const loadUrl = (url) => {
    if (isLoading) {
        return;
    }

    $mainContent.attr('aria-busy', 'true');
    gsap.to($mainContentInner.get(0), { duration: 0.2, opacity: 0 });
    //this.loader.show();
    $inpageLoader.css({ display: 'block', opacity: 0 }).addClass('animated');
    gsap.to($inpageLoader.get(0), { duration: 0.2, delay: 0.2, opacity: 1, overwrite: true });

    document.title = 'Loading...';


    isLoading = true;

    superagent
        .get(url)
        .set({ 'X-Requested-With': 'XMLHttpRequest' })
        .then(({ status, text }) => {
            if (status !== 200 || !text) {
                throw new Error();
            }

            const $newData = $('<div>' + text + '</div>');
            const newTitle = $newData.find('.loaded-content').data('page-title');

            insertLoadedUrl($newData);

            currentUrl = url;

            // google analytics tracking
            if (typeof window.gtag === "function") {
                gtag('event', 'page_view', {
                  page_title: newTitle,
                  //page_location: '<Page Location>',
                  page_path: url.replace('https://' + window.location.hostname, ''),
                  send_to: 'G-LYEWM8FVSR'
                });
            }
        })
        .catch(error => {
            console.error(error);
        })
        .then(() => {
            isLoading = false;
        });
};

const insertLoadedUrl = function($data) {
    const newTitle = $data.find('.loaded-content').data('page-title');
    const newBodyClass = $data.find('.loaded-content').data('body-class');

    if ($data.find('.loaded-content').data('body-class').indexOf('is-frontpage') !== -1) {
        document.title = 'First Frame';
    } else {
        document.title = newTitle + ' – First Frame';
    }

    removeClassByPrefix($body.get(0), 'is-');
    $body.removeClass('inverted-menu');
    $body.addClass(newBodyClass, 'is-ajax-loaded');
    //this.get_pagetype();

    // remove old instansiated content objects
    Components.destroy($mainContentInner);

    $mainContentInner.empty();
    gsap.set(window, { scrollTo: { y: 0 } });
    $mainContentInner.append($data.find('.loaded-content'));

    setTimeout(() => {
        Components.init($mainContentInner)
        Animator.init();
        
        $mainContentInner.css({ opacity: 0 });
        $mainContent.attr('aria-busy', null);
        gsap.to($mainContentInner.get(0), { duration: 0.3, delay: 0.3, opacity: 1, ease: 'sine.out' });
        gsap.to($inpageLoader.get(0), {
            duration: 0.2, opacity: 0, overwrite: true, onComplete: function() {
                $inpageLoader.css({ display: 'none' }).removeClass('animated');
            }
        });
    }, 500);

    //this.loader.hide();


};


const removeClassByPrefix = (node, prefix) => {
    const regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
    node.className = node.className.replace(regx, '');
    return node;
}

const getMaxWindowTopPos = $content => {
    const visible_area = Viewport.height;
    if (!$content) {
        $content = $('body');
    }
    const content_height = $content.outerHeight();
    return content_height - visible_area;
};

const scrollToTop = () => {
    gsap.to(window, {
        duration: 1, scrollTo: { y: 0 }, ease: 'quart.out', onComplete: () => {
            $('#top').focus();
        }
    });
};


const openShareWindow = (href) => {
    const left = (screen.width / 2) - (600 / 2);
    const top = (screen.height / 2) - (600 / 2);
    window.open(href, 'shareWindow', 'height=600,width=600, top=' + top + ',left=' + left + ',toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no');
};


const toggleImpressum = ($link) => {
    const $impressum = $('#impressum');
    const impressumOffset = $impressum.offset().top;

    if (impressumVisible) {
        $impressum.css({ height: $impressum.height() });
        gsap.to($impressum.get(0), { duration: 0.4, delay: 0.5, height: 0, ease: 'sine.inOut' });
        gsap.to(window, { duration: 0.6, scrollTo: { y: impressumOffset - Viewport.height }, ease: 'quart.inOut' });
    } else {
        $impressum.css({ height: 'auto' });
        const targetHeight = $impressum.height();

        $impressum.css({ height: 0 });

        gsap.to($impressum.get(0), {
            duration: 0.4, height: targetHeight, ease: 'sine.inOut', onComplete: () => {
                $impressum.css({ height: 'auto' });
                gsap.to(window, { duration: 0.8, scrollTo: { y: impressumOffset }, ease: 'sine.inOut' });
            }
        });
    }

    impressumVisible = !impressumVisible;
};

require('doc-ready')(init);
