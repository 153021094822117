import $ from '../core/Dom';
import gsap from 'gsap';
import { loadMapbox } from "../lib/async-bundles";

export default el => {
    let $el = $(el);
    let map = null;

    let mapbox = null;

    const init = () => {
        loadMapbox(loadedMapbox => {
            mapbox = loadedMapbox;

            mapbox.accessToken = 'pk.eyJ1IjoidmFlcnNhYWdvZCIsImEiOiJXeEZ4Z2g4In0.r35861_EPsHP2hB2Ig3PAw';

            const coords = [$el.data('map-longitude'), $el.data('map-latitude')];

            map = new mapbox.Map({
                container: 'map', // container ID
                style: 'mapbox://styles/vaersaagod/clpdte6o200cm01pk8z7a2kj9', // style URL
                center: coords, // starting position [lng, lat]
                zoom: 16, // starting zoom
                scrollZoom: false,
            });

            const nav = new mapbox.NavigationControl({
                visualizePitch: true
            });
            map.addControl(nav, 'top-right');

            let url = '/assets/static/map-marker.png';
            const markerImg = document.createElement("img");

            if (window.devicePixelRatio > 1.5) {
                url = '/assets/static/map-marker@2x.png';
            }

            markerImg.src = url;
            markerImg.style.width = '50px';
            markerImg.style.height = '50px';

            // Add markers to the map.
            new mapbox.Marker(markerImg)
                .setLngLat([$el.data('map-longitude'), $el.data('map-latitude')])
                .addTo(map);
        });


    };

    const destroy = () => {
        if (mapbox && map) {
            map.remove();
        }
    };

    return {
        init,
        destroy
    };

};
