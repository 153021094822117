import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import TinyGesture from 'tinygesture';

import * as eventKeys from '../lib/events';

export default el => {
    let $container = $(el);

    let $slides = $container.find('.frontpage-slide');
    let $slideTextWrappers = $container.find('.frontpage-slide .text-wrapper');
    let $slideImages = $container.find('.frontpage-slide img');
    let $videos = null;

    let currentSlideIndex = 0;
    let $currentSlide = null;

    let currentWidth = 0;
    let currentHeight = 0;
    let slideshowInterval = -1;

    const init = () => {
        currentWidth = Viewport.width;
        currentHeight = Viewport.height;

        $currentSlide = $slides.first();

        $slides.css({ position: 'fixed', width: '100%' });

        resizeImageArea(currentWidth, currentHeight);
        gsap.to($currentSlide.get(0), { duration: 0.5, delay: 0.5, opacity: 1 });

        $slides.each((item, i) => {
            const $slide = $(item);

            if ($slide.hasClass('video')) {
                const mp4File = $slide.data('video-mp4');
                const ogvFile = $slide.data('video-ogv');

                const $videoElem = $('<video class="slide-video absolute max-w-none" src="' + mp4File + '" loop muted playsinline></video>');
                $slide.append($videoElem);
                $slide.find('.image-wrapper').css({ display: 'none' });
            }
        });

        $videos = $container.find('.frontpage-slide .slide-video');

        setMenuColor();
        startVideo($currentSlide);

        // do initial resizing
        resizeImageArea(currentWidth, currentHeight);

        setTimeout(function() {
            resizeImageArea(currentWidth, currentHeight);
        }, 1000);

        setTimeout(function() {
            resizeImageArea(currentWidth, currentHeight);
        }, 2000);

        $(window).on('focus', function() {
            startInterval();
        });

        $(window).on('blur', function() {
            stopInterval();
        });

        startInterval();

        // swipes
        const gesture = new TinyGesture($container.get(0), {});
        gesture.on('swiperight', (event) => { swipeRight(); });
        gesture.on('swipeleft', (event) => { swipeLeft(); });
        
        // listeners
        Viewport.on('resize', resize);
        Viewport.on('scroll', scroll);
        Dispatch.on(eventKeys.WORK_PANEL_OPENED, workPanelOpened);
        Dispatch.on(eventKeys.WORK_PANEL_CLOSED, workPanelClosed);
    };


    const resize = () => {
        resizeImageArea(Viewport.width, Viewport.height);
    };

    const scroll = () => {
        gsap.set($slides.nodes, { y: -Viewport.scrollTop / 4 });
        gsap.set($slideTextWrappers.nodes, { opacity: 1 - (Viewport.scrollTop / 200) });
    };

    const startInterval = () => {
        clearInterval(slideshowInterval);
        slideshowInterval = setInterval(() => {
            nextSlide();
        }, 10000);

    };

    const stopInterval = () => {
        clearInterval(slideshowInterval);
    };

    const resizeImageArea = (w, h) => {
        currentWidth = w;
        currentHeight = h;

        if ($container === null || $container.length === 0) {
            return;
        }

        $container.css({ height: h });
        $slides.css({ height: h });

        // video 
        if ($videos !== null) {
            $videos.each((item, i) => {
                const $video = $(item);

                $video.css({ height: '', width: '', left: 0, top: 0 });

                if ((w / 16) * 9 < h) {
                    $video.css({ width: Math.round((h / 9) * 16), height: h, left: Math.round(w - ((h / 9) * 16)) });
                } else {
                    $video.css({ width: w, height: Math.round((w / 16) * 9), top: Math.round(h - ((w / 16) * 9)) });
                }
            });
        }
    };

    const nextSlide = () => {
        currentSlideIndex += 1;

        if (currentSlideIndex >= $slides.length) {
            currentSlideIndex = 0;
        }

        const $oldSlide = $currentSlide;
        const $nextSlide = $slides.eq(currentSlideIndex);
        $currentSlide = $nextSlide;

        $slides.css({ zIndex: 1 });

        if ($oldSlide.length) {
            $oldSlide.css({ zIndex: 2 });
        }
        $nextSlide.css({ zIndex: 3, display: 'block' });

        $nextSlide.css({ clip: 'rect(0px, ' + currentWidth + 'px, ' + currentHeight + 'px, ' + currentWidth + 'px)' });

        startVideo($nextSlide);

        gsap.to($nextSlide.get(0), {
            duration: 1.1,
            clip: 'rect(0px, ' + currentWidth + 'px, ' + currentHeight + 'px, 0px)', ease: 'quint.inOut', onComplete: () => {
                $nextSlide.css({ clip: '' });
                setMenuColor();
                stopVideo($oldSlide);
            }
        });
    };

    const swipeLeft = () => {
        startInterval();
        nextSlide();
    };

    const swipeRight = () => {
        startInterval();
        nextSlide();
    };

    const setMenuColor = () => {
        if ($currentSlide.data('color') === 'dark') {
            $('body').removeClass('inverted-menu');
        } else {
            $('body').addClass('inverted-menu');
        }
    };

    const startVideo = $slide => {
        const $video = $slide.find('.slide-video');

        if ($video.length > 0) {
            $video.get(0).play();
        }
    };


    const stopVideo = $slide => {
        const $video = $slide.find('.slide-video');

        if ($video.length > 0) {
            $video.get(0).pause();
        }
    };

    const workPanelOpened = () => {
        stopInterval();
        stopVideo($currentSlide);
    };

    const workPanelClosed = () => {
        startInterval();
        startVideo($currentSlide);
    };

    const destroy = () => {
        gsap.killTweensOf($currentSlide);

        clearInterval(slideshowInterval);

        $currentSlide = null;
        $container = null;
        $slides = null;
        $slideTextWrappers = null;
        $slideImages = null;
        $videos = null;

        $(window).off('focus');
        $(window).off('blur');

        Viewport.off('resize', resize);
        Viewport.off('scroll', scroll);
        Dispatch.off(eventKeys.WORK_PANEL_OPENED, workPanelOpened);
        Dispatch.off(eventKeys.WORK_PANEL_CLOSED, workPanelClosed);
    };

    return {
        init,
        destroy
    };
};
