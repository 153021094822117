import $ from '../core/Dom';

export default (el, props) => {
    const $el = $(el);
    const { shareUrl } = props; 
    
    const $classicButtons = $el.find('[data-share-classic]');
    const $apiButton = $el.find('[data-share-api]');

    const init = () => {
        if (navigator.canShare) {
            $apiButton.attr('hidden', null);
            $classicButtons.css({ display: 'none' });
            
            $apiButton.on('click', () => {
                share();
            });
        }
    };

    const destroy = () => {
        
    };
    
    const share = () => {
        try {
          navigator.share({ url: shareUrl });
        } catch(err) {
          console.error('Error: ' + err);  
        }
    };

    return {
        init,
        destroy
    };
};
