import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

let isScrolling = false;

let items = [];
let recheckTimeout = -1;

let lastEventDelay = 0;
let lastEventTime = (new Date()).getTime();

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    continueInit();
};

const continueInit = () => {
    const $animatorItems = $('body').find('.sm-item-animation');
    
    $animatorItems.each(el => {
        const $el = $(el);
        const def = {};
        def.element = el;
        initElement(def);
        register(def);
    });
    
    Viewport.on('scroll', onScroll);
    Viewport.on('resize', onResize);
};

const initElement = def => {
    const $el = $(def.element);
    gsap.set(def.element, { opacity: 0.15 });
};

const register = def => {
    updateDefinition(def);

    items.push(def);
    sortItems();

    clearTimeout(recheckTimeout);
    recheckTimeout = setTimeout(() => {
        onScroll();
    }, 50);
};

const updateDefinition = def => {
    if (def.element !== undefined) {
        const $el = $(def.element);
        def.offsetY = $el.offset().top;
        def.height = $el.height();
        def.done = false;
    }
};

const sortItems = () => {
    items.sort((a, b) => (a.offsetY > b.offsetY) ? 1 : ((b.offsetY > a.offsetY) ? -1 : 0));
};

const animateItem = (item, delay) => {
    gsap.to(item.element, { duration: 0.6, delay: delay, opacity: 1, ease: 'sine.out' });
};

const completeItem = (item) => {
    gsap.set(item.element, { opacity: 1 });
};

const onScroll = () => {
    const top = Viewport.scrollTop;
    const viewportHeight = Viewport.height;

    let itemsToAnimate = [];
    let itemsToComplete = [];

    items.forEach(item => {
        if (!item.done) {
            if (item.offsetY + item.height < top) {
                itemsToComplete.push(item);
                item.done = true;
            } else if (item.offsetY < top + (viewportHeight * 0.80)) {
                itemsToAnimate.push(item);
                item.done = true;
            }
        }
    });

    if (itemsToComplete.length > 0) {
        itemsToComplete.forEach(item => {
            completeItem(item);
        });
    }

    let accumulatedDelay = Math.max(0, lastEventDelay - (((new Date()).getTime() - lastEventTime)/1000));
    
    if (itemsToAnimate.length > 0) {
        itemsToAnimate.forEach(item => {
            animateItem(item, accumulatedDelay);
            accumulatedDelay += Number.isNaN(item.offsetLength) ? 0 : item.offsetLength;
        });
    }
    
    lastEventDelay = accumulatedDelay;
    lastEventTime = (new Date()).getTime();
};

const onResize = () => {
    items.forEach(item => {
        if (!item.done) {
            updateDefinition(item);
        }
    });

    clearTimeout(recheckTimeout);
    recheckTimeout = setTimeout(() => {
        onScroll();
    }, 50);
};

export default {
    init,
    register
};
